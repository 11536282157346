<template>
  <div class="relative">
    <div class="absolute -top-2 -left-12">
      <img
        src="https://playda.s3.ap-south-1.amazonaws.com/diy/log_in_page_blob_1.webp"
        alt="Playda DIY Blob"
        width="120"
        height="102"
      />
    </div>
    <div class="register grid">
      <div v-if="emailSection">
        <div class="mt-10">
          <div class="flex items-center justify-center">
            <img
              src="https://playda.s3.ap-south-1.amazonaws.com/playda-logos/playda-logo-dark.webp"
              alt="Playda Logo"
              width="140"
              height="50"
            />
          </div>
          <div class="font-InterSemiBold text-lg mt-4 text-white mx-4">
            Welcome to the gaming extravaganza!
          </div>
          <div class="mt-6 mx-4 text-white">
            <div class="grid gap-2">
              <div>
                <p class="font-InterSemiBold uppercase">Full name</p>
                <input
                  type="text"
                  name="name"
                  id="name"
                  v-model="name"
                  class="textInput"
                  placeholder="What do we call you?"
                />
              </div>
              <div>
                <p class="font-InterSemiBold uppercase">Email ID</p>
                <input
                  type="email"
                  name="email"
                  id="email"
                  v-model="email"
                  class="textInput"
                  placeholder="Preferred Registration Email"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="mt-6 mx-4 grid items-center">
          <div>
            <button
              @click.prevent="validateEmail()"
              :class="[
                'bg-nColorYellow rounded-3xl w-full h-14 text-nColorButtonText text-center uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none',
                { disabled: isValidateLoading },
              ]"
              :disabled="isValidateLoading"
            >
              <span v-if="isValidateLoading" class="flex justify-center">
                <svg
                  width="60"
                  height="15"
                  viewBox="0 0 120 30"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#fff"
                >
                  <circle cx="15" cy="15" r="15">
                    <animate
                      attributeName="r"
                      from="15"
                      to="15"
                      begin="0s"
                      dur="0.8s"
                      values="15;9;15"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="fill-opacity"
                      from="1"
                      to="1"
                      begin="0s"
                      dur="0.8s"
                      values="1;.5;1"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                  </circle>
                  <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                    <animate
                      attributeName="r"
                      from="9"
                      to="9"
                      begin="0s"
                      dur="0.8s"
                      values="9;15;9"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="fill-opacity"
                      from="0.5"
                      to="0.5"
                      begin="0s"
                      dur="0.8s"
                      values=".5;1;.5"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                  </circle>
                  <circle cx="105" cy="15" r="15">
                    <animate
                      attributeName="r"
                      from="15"
                      to="15"
                      begin="0s"
                      dur="0.8s"
                      values="15;9;15"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="fill-opacity"
                      from="1"
                      to="1"
                      begin="0s"
                      dur="0.8s"
                      values="1;.5;1"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                  </circle>
                </svg>
              </span>
              <span v-else> GET OTP </span>
            </button>
          </div>
          <div class="w-full pl-4">
            <div class="text-sm inline-block mt-2 h-4">
              <div v-if="errorMsg" class="error text-red-600 rounded">
                <p class="text-sm">{{ errorMsg }}</p>
              </div>
              <div v-if="successMsg" class="success text-green-700">
                <p class="text-sm">
                  {{ successMsg }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="w-11/12 mx-4 flex gap-2 mt-2 items-center">
            <hr class="w-1/2 border-nColorLightGray" />
            <div class="text-nColorLightGray">OR</div>
            <hr class="w-1/2 border-nColorLightGray" />
          </div>
          <div class="w-11/12 flex justify-center mt-4 mx-4">
            <div class="h-20">
              <GoogleLogin
                :callback="signupUsingGoogle"
                :buttonConfig="{
                  size: 'large',
                  shape: 'pill',
                  text: 'sign up using',
                  width: '340',
                }"
                prompt
              />
            </div>
          </div>
        </div>
        <div class="mt-6 mx-4">
          <a href="/" class="font-InterSemiBold underline text-white"
            >Already have an account? Log in</a
          >
        </div>
      </div>
      <div v-if="otpSection">
        <div>
          <div class="font-InterRegular text-xs mt-10">
            <div class="flex items-center justify-center">
              <img
                src="https://playda.s3.ap-south-1.amazonaws.com/playda-logos/playda-logo-dark.webp"
                alt="Playda Logo"
                width="140"
                height="50"
              />
            </div>
            <div class="font-InterSemiBold text-lg mt-4 mx-4 text-white">
              Welcome to the gaming extravaganza!
            </div>
          </div>
          <div class="flex gap-6 mt-4 mx-4">
            <input
              v-for="(i, index) in 4"
              :key="i"
              @keydown.space.prevent=""
              maxlength="1"
              class="otpText"
              type="number"
              @input="handleInput(i, $event)"
              @keydown.delete="movePrev(i)"
              :ref="'otp' + i"
              :autofocus="index === 0"
            />
          </div>
          <div class="grid gap-4 mx-4">
            <div class="mt-6">
              <div>
                <span class="font-InterRegular text-sm text-nColorLightGray"
                  >Enter the 4-digit OTP that we have sent to your email</span
                >
                <span class="font-InterMedium text-sm ml-1 text-nColorOrange"
                  ><strong>{{ email }}</strong></span
                >
              </div>
            </div>
            <div class="flex justify-between items-center">
              <div class="text-nColorOrange justify-center">
                <button
                  @click="enableEmail"
                  class="underline font-InterSemiBold text-sm"
                >
                  Change email
                </button>
              </div>
              <div class="font-ManropeMedium text-sm">
                <button :disabled="resendDisabled" @click="resendOTP">
                  <span
                    :class="{
                      'text-gray-400': resendDisabled,
                      'text-nColorOrange underline font-bold': !resendDisabled,
                    }"
                  >
                    Resend OTP
                  </span>
                  <span v-if="resendDisabled" class="text-gray-400"
                    >({{ countdown }})</span
                  >
                </button>
              </div>
            </div>
          </div>
          <div class="mt-6 mx-4">
            <button
              @click.prevent="validateOTP()"
              :class="[
                'bg-nColorYellow rounded-3xl w-full h-14 text-nColorButtonText text-center uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none',
                { disabled: isRegisterLoading },
              ]"
              :disabled="isRegisterLoading"
            >
              <span v-if="isRegisterLoading" class="flex justify-center">
                <svg
                  width="60"
                  height="15"
                  viewBox="0 0 120 30"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000"
                >
                  <circle cx="15" cy="15" r="15">
                    <animate
                      attributeName="r"
                      from="15"
                      to="15"
                      begin="0s"
                      dur="0.8s"
                      values="15;9;15"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="fill-opacity"
                      from="1"
                      to="1"
                      begin="0s"
                      dur="0.8s"
                      values="1;.5;1"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                  </circle>
                  <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                    <animate
                      attributeName="r"
                      from="9"
                      to="9"
                      begin="0s"
                      dur="0.8s"
                      values="9;15;9"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="fill-opacity"
                      from="0.5"
                      to="0.5"
                      begin="0s"
                      dur="0.8s"
                      values=".5;1;.5"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                  </circle>
                  <circle cx="105" cy="15" r="15">
                    <animate
                      attributeName="r"
                      from="15"
                      to="15"
                      begin="0s"
                      dur="0.8s"
                      values="15;9;15"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="fill-opacity"
                      from="1"
                      to="1"
                      begin="0s"
                      dur="0.8s"
                      values="1;.5;1"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                  </circle>
                </svg>
              </span>
              <span v-else> Register </span>
            </button>
            <div class="w-full">
              <div class="text-sm inline-block mt-2 h-4">
                <div v-if="errorMsgSubmit" class="error text-red-600 rounded">
                  <p class="text-sm">{{ errorMsgSubmit }}</p>
                </div>
                <div v-if="successMsgSubmit" class="success text-green-700">
                  <p class="text-sm">
                    {{ successMsgSubmit }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-6 mx-4">
            <a href="/" class="font-InterSemiBold underline text-white"
              >Already have an account? Log in</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import { decodeCredential } from "vue3-google-login";
import { useToast } from "vue-toastification";
import TokenService from "@/common/token.service";

const toast = useToast();

export default {
  name: "Register",
  components: {},
  data() {
    return {
      name: null,
      email: null,
      isValidateLoading: false,
      isRegisterLoading: false,
      emailSection: true,
      otpSection: false,
      resendDisabled: false,
      countdown: 60,
      otp: "",
      errorMsg: "",
      successMsg: "",
      errorMsgSubmit: "",
      successMsgSubmit: "",
      marketingURL: process.env.VUE_APP_MARKETING_URL,
      contactURL: process.env.VUE_APP_CONTACT_URL,
    };
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    signupUsingGoogle(response) {
      let userData = decodeCredential(response.credential);
      ApiService.post(apiResource.continueWithGoogle, {
        name: userData.name,
        email: userData.email,
        picture: userData.picture,
      }).then((data) => {
        this.isLoginLoading = false;
        this.$store.dispatch("validateLoginOTP", data);
        this.goToDashboard();
      });
    },
    validateEmail() {
      this.isValidateLoading = true;
      this.errorMsg = "";
      if (this.name && this.email) {
        ApiService.post(apiResource.validateCustomer, {
          name: this.name,
          email: this.email,
        })
          .then(() => {
            this.isValidateLoading = false;
            this.emailSection = false;
            this.otpSection = true;
            this.startResendCounter();
          })
          .catch((error) => {
            this.isValidateLoading = false;
            this.successMsg = null;
            if (typeof error.response.data.msg === "object") {
              this.errorMsg =
                error.response.data.msg[
                  Object.keys(error.response.data.msg)[0]
                ];
            } else {
              this.errorMsg = error.response.data.msg;
            }
          });
      } else {
        this.isValidateLoading = false;
        this.errorMsg = "Please fill all the fields";
      }
    },
    resendOTP() {
      ApiService.post(apiResource.resendOTP, {
        email: this.email,
      })
        .then(() => {
          this.startResendCounter();
          toast.success("OTP resent successfully!", { timeout: 2000 });
        })
        .catch(() => {});
    },
    validateOTP() {
      this.isRegisterLoading = true;
      this.errorMsgSubmit = "";
      console.log(this.otp);
      if (this.otp) {
        ApiService.post(apiResource.validateOTP, {
          email: this.email,
          otp: this.otp,
        })
          .then((data) => {
            this.isRegisterLoading = false;
            this.$store.dispatch("validateOTP", data);
            this.goToDashboard();
          })
          .catch((error) => {
            this.isRegisterLoading = false;
            this.successMsgSubmit = null;
            if (typeof error.response.data.msg === "object") {
              this.errorMsgSubmit =
                error.response.data.msg[
                  Object.keys(error.response.data.msg)[0]
                ];
            } else {
              this.errorMsgSubmit = error.response.data.msg;
            }
          });
      } else {
        this.isRegisterLoading = false;
        this.errorMsgSubmit = "Please enter the OTP";
      }
    },
    goToDashboard() {
      var pricingPlan = TokenService.getToken("pricing_plan");
      if (pricingPlan) {
        this.$router.replace({
          name: "Payment",
        });
      } else {
        this.$router.replace({
          name: "Dashboard",
        });
      }
    },
    handleInput(index, event) {
      const inputValue = event.target.value;

      if (inputValue.length > 1) {
        event.target.value = inputValue.charAt(0);
      }

      if (inputValue.length === 1 && index < 4) {
        const nextInputRef = `otp${index + 1}`;
        const nextInput = this.$refs[nextInputRef][0];
        nextInput.focus();
      }

      if (inputValue.length === 0 && index > 1) {
        const prevInputRef = `otp${index - 1}`;
        const prevInput = this.$refs[prevInputRef][0];
        prevInput.value = ""; // Clear previous input value
        prevInput.focus();
      }

      this.updateOTPValue();
    },
    movePrev(index) {
      if (index > 1) {
        const currentInputRef = `otp${index}`;
        const currentInput = this.$refs[currentInputRef][0];
        const prevInputRef = `otp${index - 1}`;
        const prevInput = this.$refs[prevInputRef][0];

        currentInput.value = ""; // Clear current input value
        prevInput.value = ""; // Clear previous input value
        prevInput.focus();
      }
    },
    updateOTPValue() {
      const otpInputValues = Array.from(
        document.querySelectorAll(".otpText")
      ).map((input) => input.value);

      this.otp = otpInputValues.join("");
    },
    startResendCounter() {
      this.resendDisabled = true;
      this.countdown = 60;

      const timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.resendDisabled = false;
          clearInterval(timer);
        }
      }, 1000);
    },
    enableEmail() {
      window.location.reload();
    },
  },
};
</script>

<style scoped lang="postcss">
.register {
  height: 100dvh;
}
.textInput {
  @apply w-full text-white pt-2 pb-3 focus:outline-none bg-nColorTextInput rounded-xl px-4;
}
.radio-group {
  display: flex;
}

.radio-selector {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

/* Style the radio input to be hidden */
input[type="radio"] {
  display: none;
}
.selected {
  @apply border-nColorOrange text-nColorOrange;
}
.otpText {
  @apply bg-nColorTextInput rounded-xl font-InterBold text-nColorYellow text-3xl focus:outline-none focus:bg-nColorYellow focus:text-nColorButtonText text-center w-16 h-16 uppercase py-1 px-1;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
